import './RequestCreation.css';
import '../../css/shared.css';
import FileUploadIcon from '../../static/images/file_upload.svg';
import ExitIcon from '../../static/images/exit_icon.svg';
import { FileInfo } from '../FileInfo/FileInfo';
import { useEffect, useState } from 'react';
import { orderService } from '../../http/order';
import WarningInfoIcon from '../../static/images/warning_info.svg';
import { Modal, notification } from 'antd';
import { useNavigate } from "react-router-dom";
import { useOrder } from '../../hooks/order.hook';
import { ExitModal } from '../ExitModal/ExitModal';
import { Notification } from '../Notifications/Notification';

export function RequestCreation(props) {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isListingFileSelected, setIsListingFileSelected] = useState(false);
  const [selectedListingFile, setSelectedListingFile] = useState(null);
  const [isErrorOccured, setIsErrorOccured] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isListingErrorOccured, setIsListingErrorOccured] = useState(false);
  const [listingErrorMessage, setListingErrorMessage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { setOrder, setBatches, setIsLoading, order, organisation, listingUser } = useOrder();
  const { moveToNextStep } = props;
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isMatchingNotificationShown, setIsMatchingNotificationShown] = useState(false);
  const [isCatlogingFileUploaded, setIsCatlogingFileUploaded] = useState(false);

  const navigate = useNavigate();

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFileSelected(true);
    setIsCreatingOrder(true);
  }

  const onListingFileChange = (event) => {
    setSelectedListingFile(event.target.files[0]);
    setIsListingFileSelected(true);
    setIsUpdatingOrder(true);
  }

  const cancelUploadedFile = async () => {
    const response = await orderService.rejectOrder(order._id);
    if (response.data.modifiedCount === 1) {
      setIsFileSelected(false);
      setSelectedFile(null);
      setIsErrorOccured(false);
      setErrorMessage('');
      // deleting catalog file rejects the order
      setIsListingFileSelected(false);
      setSelectedListingFile(null);
      setIsListingErrorOccured(false);
      setListingErrorMessage('');
      setOrder(null);
      setIsCatlogingFileUploaded(false)
    }
  }

  const cancelUploadedListingFile = async () => {
    //console.log('cancel uploaded listing file');
    const response = await orderService.removeOrderFile(order?._id, 'listing');
    if (response && response.data) {
      setIsListingFileSelected(false);
      setSelectedListingFile(null);
    } else {
      console.log('Failed to Remove File')
    }
  }

  const setMatchingNotificationTrue = async (order) => {
    if (order && order.file_url && order.listing_file_url) {
      console.log('setting notification true for the saved request');
      setIsMatchingNotificationShown(true);
    }
  }

  useEffect(() => {
    if (order) {
      console.log('order details');
      // console.log(order);
      if (order.status === 'PENDING') {
        const fileUrl = decodeURI(order.file_url);
        const fileParts = fileUrl.split('/');
        // console.log(fileParts);
        let filename = fileParts[fileParts.length - 1];
        filename = filename.substring(filename.indexOf('_') + 1, filename.length)
        setSelectedFile({
          name: filename,
          size: order.file_size
        })
        setIsFileSelected(true);

        // set listing file
        if (order?.listing_file_url) {
          const listingFileUrl = decodeURI(order.listing_file_url);
          const listingFileParts = listingFileUrl.split('/');
          let listingfilename = listingFileParts[listingFileParts.length - 1];
          listingfilename = listingfilename.substring(listingfilename.indexOf('_') + 1, listingfilename.length)
          setSelectedListingFile({
            name: listingfilename,
            size: order.listing_file_size
          })
          setIsListingFileSelected(true);
          // console.log('listing file data set');
          setMatchingNotificationTrue(order);
        }
      }
    }
  }, [order])

  useEffect(() => {
    if (selectedFile && isCreatingOrder === true) {
      try {
        findIsValidFileFormat(selectedFile.name, 'catalog');
        // console.log('creating order', order)
        createOrder();
      } catch (err) {
        console.error(err);
      }
    }
  }, [isCreatingOrder, selectedFile])

  useEffect(() => {
    if (selectedListingFile && isUpdatingOrder === true) {
      try {
        findIsValidFileFormat(selectedListingFile.name, 'listing');
        console.log('selected Listing File(use effect) file is valid')
        updateOrder();
      } catch (err) {
        console.error(err);
      }
    }
  }, [isUpdatingOrder, selectedListingFile])

  const allowedExtensions = ['xlsx', 'xls', 'xlsb'];

  const updateProgress = (progress) => {
    const { loaded, total } = progress;
    setUploadProgress((loaded / total) * 100)
  }

  const allowDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  }

  const drop = async (ev) => {
    ev.preventDefault();
    if (Object.keys(ev.dataTransfer.files).length > 1) {
      setIsErrorOccured(true);
      setErrorMessage('Sorry! Only one template can be uploaded at a time. Please retry');
      if (order) {
        await cancelUploadedFile();
        setIsFileSelected(false);
        setSelectedFile(null);
      }
    } else {
      if (selectedFile != null) {
        setIsFileSelected(false);
        setSelectedFile(null);
        setIsErrorOccured(true);
        await cancelUploadedFile();
        setErrorMessage('Sorry! Only one template can be uploaded at a time. Please retry');
      } else {
        setSelectedFile(ev.dataTransfer.files[0]);
        setIsFileSelected(true);
        setIsCreatingOrder(true);
      }
    }
  }

  const dropListing = async (ev) => {
    ev.preventDefault();
    if (Object.keys(ev.dataTransfer.files).length > 1) {
      setIsListingErrorOccured(true);
      setListingErrorMessage('Sorry! Only one template can be uploaded at a time. Please retry');
      if (order) {
        await cancelUploadedListingFile();
        setIsListingFileSelected(false);
        setSelectedListingFile(null);
      }
    } else {
      if (selectedListingFile != null) {
        setIsListingFileSelected(false);
        setSelectedListingFile(null);
        setIsListingErrorOccured(true);
        await cancelUploadedListingFile();
        setListingErrorMessage('Sorry! Only one template can be uploaded at a time. Please retry');
      } else {
        setSelectedListingFile(ev.dataTransfer.files[0]);
        setIsListingFileSelected(true);
        setIsUpdatingOrder(true);
      }
    }
  }

  const findIsValidFileFormat = (fileName, fileType = 'catalog') => {
    const fileparts = fileName.split('.');
    const extension = fileparts[fileparts.length - 1];

    if (fileType === 'catalog') {
      if (!allowedExtensions.includes(extension)) {
        setIsFileSelected(false);
        setSelectedFile(null);
        setIsErrorOccured(true);
        setIsCreatingOrder(false);
        setErrorMessage('Sorry! This file format is not supported. Please try using an Excel file');
        throw 'file format not supported';
      } else {
        setIsErrorOccured(false);
        setErrorMessage('');
      }
    } else if (fileType === 'listing') {
      if (!allowedExtensions.includes(extension)) {
        setIsListingFileSelected(false);
        setSelectedListingFile(null);
        setIsListingErrorOccured(true);
        setIsCreatingOrder(false);
        setListingErrorMessage('Sorry! This file format is not supported. Please try using an Excel file');
        throw 'file format not supported';
      } else {
        setIsListingErrorOccured(false);
        setListingErrorMessage('');
      }
    }

  }

  const rejectOrder = async (orderId) => {
    // console.log('rejecting the order', orderId);
    const response = await orderService.rejectOrder(orderId);
    setIsModalVisible(false);
    navigate('/');
    return response;
  }

  const startMappingProcess = async () => {
    console.log('starting mapping process');
    if(!listingUser.includes(organisation)){
      const batchStatus = await orderService.getBatches(order._id)
      console.log(batchStatus.data);
      setBatches(batchStatus.data.batches)
    } 
    const response = await orderService.startMapping(order._id);
    if (response.data.success === true) {
      setOrder(response.data.message.updatedOrder);
      console.log('mapping process started');
      moveToNextStep();
    }
  }

  const createOrder = async () => {
    // console.log('create order fn');
    const formData = new FormData();
    formData.set('file', selectedFile);
    // console.log("what is the order", selectedFile, formData);
    const response = await orderService.createOrder(formData, updateProgress, 'catalog', order?._id);
    // console.log("jflsfjslkfjslfjslkfsfsklflk",response)
    setIsCreatingOrder(false);
    if (response.data.success == true) {
      const missedSkus = response.data.data.missingSkus;
      const totalSkus = response.data.data.totalSkus;
      if (missedSkus !== 0 && listingUser.includes(organisation)) {
        notification.warn({
          message: 'Some units were missed',
          description: (
            <>
              This input file contains units that have to be split and mapped to multiple output templates.
              <br/><br/>
              {totalSkus - missedSkus} out of {totalSkus} units will be mapped to the appropriate template(s).
              Remaining {missedSkus} units contain values that are not configured yet and hence need to be
              skipped.
            </>
          ),
          duration: 10
        });
      }
      setIsErrorOccured(false);
      setErrorMessage('');
      setOrder(response.data.data.order);
      setBatches(response.data.data.batches);
      setIsLoading(false);
      setIsCatlogingFileUploaded(true)
    } else {
      setIsErrorOccured(true);
      setErrorMessage(response.data.error);
      setIsFileSelected(false);
      setSelectedFile(null);
    }
  }

  const showMatchingNotification = async (matchingData) => {
    setIsMatchingNotificationShown(true);
    if (!matchingData) return
    const total = matchingData['total'];
    const matching = matchingData['matching'];
    if (total === matching) return
    if (matching < total) {
      Notification('info', `${matching}/${total} matched`, `Found only ${matching}/${total} matching units between Catalog File & Listing File`)
    }
  }

  const updateOrder = async () => {
    console.log('update order fn')
    setIsMatchingNotificationShown(false);
    const formData = new FormData();
    formData.set('file', selectedListingFile);
    const response = await orderService.createOrder(formData, updateProgress, 'listing', order?._id);
    setIsUpdatingOrder(false);
    if (response.data.success == true) {
      setIsListingErrorOccured(false);
      setListingErrorMessage('');
      setOrder(response.data.data.order);
      setBatches(response.data.data.batches);
      showMatchingNotification(response.data.data.matchingData);
      setIsLoading(false);
    } else {
      setIsListingErrorOccured(true);
      setListingErrorMessage(response.data.error);
      setIsListingFileSelected(false);
      setSelectedListingFile(null);
    }
  }
  return (
    <>
      <section className="request-creation flex-box flex-column-dir">
        <ExitModal orderId={order?._id}/>
        <div className="create-request flex-box">
          <span className='file-header'>Upload Catalog File</span>
          {!isFileSelected && <section className="request-creation__file-upload flex-box flex-column-dir" onDrop={drop}
                                       onDragOver={allowDrop} onDragEnter={allowDrop}>
            {(!isFileSelected || isErrorOccured) && <label className="request-creation__info flex-box flex-column-dir">
              <img src={FileUploadIcon}/>
              <input accept={allowedExtensions.map(ext => '.' + ext).join(',')} onChange={onFileChange} type="file"
                     id="orderFile" style={{ display: 'none' }}></input>
              <p htmlFor="orderFile" className="request-creation__text">Click to browse or <br/> drag and drop your
                files</p>
            </label>}
            {isErrorOccured &&
              <p><img src={WarningInfoIcon}/><span className='file-upload-err-msg'>{errorMessage}</span></p>}
            {/* { (isFileSelected && !isErrorOccured) && <div className="request-file-info">
                        <FileInfo order={order} isCreatingOrder={isCreatingOrder} uploadProgress={uploadProgress} cancelUploadedFile={cancelUploadedFile} file={selectedFile} />
                    </div> } */}
          </section>}
          {(isFileSelected && !isErrorOccured) && <div className="request-file-info">
            <FileInfo
              order={order}
              isCreatingOrder={isCreatingOrder}
              uploadProgress={uploadProgress}
              cancelUploadedFile={cancelUploadedFile}
              file={selectedFile}
              fileType='catalog'
              isListingFileSelected={isListingFileSelected}
            />
          </div>}
        </div>

        {/* Below code is for listing file upload */}
        <div className={`create-request flex-box ${!isFileSelected ? 'disabled_div' : ''}`} style={{display:(listingUser.includes(organisation)) ? 'block' : 'none'}}>
          <span className='file-header'>Upload Listing File</span>
          {!isListingFileSelected &&
            <section className="request-creation__file-upload flex-box flex-column-dir" onDrop={dropListing}
                     onDragOver={allowDrop} onDragEnter={allowDrop}>
              {(!isListingFileSelected || isListingErrorOccured) &&
                <label className="request-creation__info flex-box flex-column-dir">
                  <img src={FileUploadIcon}/>
                  <input accept={allowedExtensions.map(ext => '.' + ext).join(',')} onChange={onListingFileChange}
                         type="file" id="orderFileListing" style={{ display: 'none' }}
                         disabled={!isFileSelected}></input>
                  <p htmlFor="orderFile" className="request-creation__text">Click to browse or <br/> drag and drop your
                    files</p>
                </label>}
              {isListingErrorOccured &&
                <p><img src={WarningInfoIcon}/><span className='file-upload-err-msg'>{listingErrorMessage}</span></p>}
            </section>}
          {(isListingFileSelected && !isListingErrorOccured) && <div className="request-file-info">
            <FileInfo
              order={order}
              isCreatingOrder={isCreatingOrder}
              uploadProgress={uploadProgress}
              cancelUploadedFile={cancelUploadedListingFile}
              file={selectedListingFile}
              fileType='listing'
              isListingFileSelected={isListingFileSelected}
            />
          </div>}
        </div>

        <div className="create-order-footer flex-box flex-end">
        {listingUser.includes(organisation) ? 
          <button onClick={() => startMappingProcess()}
                  disabled={!isFileSelected || !isListingFileSelected || !isMatchingNotificationShown}
                  className="cus-button primary flex-box proceed-btn" style={{ width: '13%' }}
          >
            <span>Confirm</span>
          </button>
          :
          <button onClick={() => startMappingProcess()}
                  disabled={!isCatlogingFileUploaded }
                  className="cus-button primary flex-box proceed-btn" style={{ width: '13%' }}
          >
            <span>Confirm</span> 
          </button>}
           {/* <button style={{width: '13%', color: '#848484'}} className="cus-button transparent flex-box">
                    <span>Back</span>
                </button> */}
        </div>
      </section>
    </>
  )
}