import { httpClient } from ".";

async function pingKriya() {
  return httpClient.get('ping-kriya');
}

async function createOrder(formData, updateProgress, fileType = 'catalog', orderId = null) {
  return httpClient.postMultiPartFormData(`api/order/upload?fileType=${fileType}&orderId=${orderId}`, formData, updateProgress)
}

async function removeOrderFile(orderId, fileType = 'listing') {
  return httpClient.put(`api/orders/removeOrderFile?orderId=${orderId}&fileType=${fileType}`, {});
}

async function completeOrder(data) {
  return httpClient.put('api/orders/complete', data)
}

async function startMapping(orderId) {
  return httpClient.put('api/orders/start-mapping', { orderId });
}

async function rejectOrder(orderId) {
  console.log('rejecting the order', orderId);
  return httpClient.put('api/orders/reject', { orderId });
}


async function getStatus(orderId) {
  return httpClient.get(`api/order/status?id=${orderId}`);
}

async function getOutputFiles(orderId) {
  return httpClient.get(`api/order/outputs?id=${orderId}`);
}

async function getBatchCounts(orderId) {
  return httpClient.get(`api/orders/orderBatchCount?orderId=${orderId}`);
}

async function fetchSKU(batchId) {
  return httpClient.get(`api/orders/fetchQcsku?batchId=${batchId}`);
}

async function fetchFilteredQcSku(batchId) {
  return httpClient.get(`api/orders/fetchFilteredQcSku?batchId=${batchId}`);
}

async function passQCForFulfilledInputs(batchId) {
  return httpClient.put(`api/orders/passQCForFulfilledInputs?batchId=${batchId}`, {});
}

async function submitSKU(skuId, newJson, skipFlag) {
  return httpClient.put(`api/orders/submitQcSku?skuId=${skuId}`, { qcData: newJson, skipFlag: skipFlag});
}

async function skipSKU(skuId) {
  return httpClient.put(`api/orders/skipQcSku?skuId=${skuId}`, {});
}

async function skipSKUCompletly(skuId) {
  return httpClient.put(`api/orders/skipQcSkuCompletly?skuId=${skuId}`, {});
}

async function downloadCallback(batchId) {
  return httpClient.put(`api/orders/downloadCallback?batchId=${batchId}`);
}

async function priceDeduction(orderId, price) {
  return httpClient.post(`api/orders/deductMeeshoPrice?order_id=${orderId}`, { amountToDeduct: price});
}

async function fetchTemplate(inputMarket, outputMarket, formData) {
  return httpClient.post(`api/order/fetchtemplates?inputMarket=${inputMarket}&outputMarket=${outputMarket}`, formData);
}

async function report(report) {
  return httpClient.post(`api/report/report`, report);
}

async function request(report) {
  return httpClient.post(`api/request/request`, report);
}

async function getBatches(orderId) {
  return httpClient.get(`api/order/batches?id=${orderId}`);
}

export const orderService = {
  pingKriya,
  createOrder,
  startMapping,
  getStatus,
  getOutputFiles,
  getBatchCounts,
  rejectOrder,
  fetchSKU,
  submitSKU,
  skipSKU,
  skipSKUCompletly,
  completeOrder,
  removeOrderFile,
  fetchFilteredQcSku,
  passQCForFulfilledInputs,
  downloadCallback,
  priceDeduction,
  fetchTemplate,
  report,
  request,
  getBatches
}
